import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const constantRoutes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index'),
    hidden: true
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/map/index'),
    hidden: true
  },
  {
    path: '/mediaList',
    name: 'mediaList',
    component: () => import('@/views/media/list'),
    hidden: true
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index'),
    hidden: true
  },
  {
    path: '/yqm',
    name: 'yqm',
    component: () => import('@/views/yqm/index'),
    hidden: true
  },
  {
    path: '/privacy',
    name: 'privacy',
    component: () => import('@/views/privacy/index'),
    hidden: true
  },
]

const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

export default router