<template>
  <div id="app">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="key" />
    </transition>
  </div>
</template>

<script>

export default {
  name: 'App',
    computed: {
    key() {
      return this.$route.path
    }
  }
}
</script>

<style>
#app {
  height: 100%;
}
</style>
