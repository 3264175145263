import Vue from 'vue'
import App from './App.vue'
import '@/styles/index.scss' // global css
import router from './router'

import VModal from 'vue-js-modal'

import alert from './modules/index'

// import BaiduMap from 'vue-baidu-map'

// Vue.use(BaiduMap, {
//   ak: 'RINxEbw337HjNtrT9IxhZhriBQeQ7hmD'
// })

Vue.use(VModal)

Vue.use(alert)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
